import React, { Component } from 'react';
import { Typography, Link } from '@material-ui/core';
import './App.css';
import LanguageSwitch from './Components/LanguageSwitch';
import ServiceDeskForm from './Components/ServiceDeskForm';
import { withTranslation, WithTranslation } from 'react-i18next';
import { BuildingsList } from './buildingList';
// import i18n from 'i18next';

// interface IProps {
//   search: String,
//   loading: boolean,
//   t: i18n.TFunction,
// };

// interface IState {
// };


class App extends Component<WithTranslation> {
  
  state = {
    validationDisplay: false,
    isBuildingFound: false,
    title: ''
  };

  changeLanguageHandler = (lang: string) => {
    this.setState({ lang });
  }

  componentDidMount () {
    // Get current Id 
    var currentId = window.location.href.split("/").pop();

    if(currentId !== '') currentId = currentId?.substring(1).replace('#', '');
    
    // Try to find this id in building list
    const buildingFound = BuildingsList.find(element => element.id === currentId);

    //this.setState({ isBuildingFound: false, title: '' });
    if(buildingFound !== undefined){
      this.setState({ isBuildingFound: true, title: buildingFound.buidingName, urlId: currentId });
    }
  }

  render()
  {
    // let title = "BELLIARD 40";
    let subTitle = "Service Desk";
    const { t } = this.props;

    const MyForm = withTranslation()(ServiceDeskForm);

    if(!this.state.isBuildingFound){
      document.body.style.backgroundColor = "#fff";
      return <div className="NoBuildingFound">
        <img
            src="../resources/images/cofinimmo-services-logo.gif"
            alt="Cofinimmo Services" />
        <Typography variant="h1">No building found for this url</Typography>
        <Typography variant="h2">Check the link or contact cofinimmo services</Typography>
        </div>
    }
    else{
      document.body.style.backgroundColor = "#8b8681";
      return (
        <>
        <div id="headerRoot">
          <img
            src="../resources/images/cofinimmo-services-logo.gif"
            alt="Cofinimmo Services" />
        </div>
        <div className="App">
            <div className="MainForm">
              <Typography variant="h1">{this.state.title}</Typography>
              <Typography variant="h2">{subTitle}</Typography>
  
              <LanguageSwitch />
  
              <MyForm />
  
              <div className="footer">
                <Link href={t('common.rgpd-link')} target="_blank">{t('common.rgpd-title')}</Link>
              </div>
  
            </div>
        </div>
        </>
      )
    }
  }
}

export default App;
