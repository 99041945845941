import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// import Backend from 'i18next-http-backend';
// import { Languages } from './Enums/Languages'
import translationEN from './i18n/en.json';
import translationFR from './i18n/fr.json';
import translationNL from './i18n/nl.json';

// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

// let defaultLanguage = Languages.EN;
 
// the translations
const resources = {
  en: {
    translation: translationEN
  },
  fr: {
    translation: translationFR
  },
  nl: {
    translation: translationNL
  }
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  //   .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    // lng: defaultLanguage,
    // fallbackLng: 'en',
    keySeparator: '.',

    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });

  i18n.changeLanguage('en');
  
export default i18n;