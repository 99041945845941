import React, { Component } from 'react';
import { Grid, Button, Typography } from '@material-ui/core';
import { default as TextFieldFormsy } from './Formsy/TextFieldFormsy';
import { WithTranslation } from 'react-i18next';
import Formsy from 'formsy-react';
import { BuildingsList } from '../buildingList';
import MailData from '../DTO/MailData';

class ServiceDeskForm extends Component<WithTranslation> {

    constructor(props: WithTranslation<"translation"> | Readonly<WithTranslation<"translation">>) {
        super(props);
        this.disableButton = this.disableButton.bind(this);
        this.enableButton = this.enableButton.bind(this);
    }

    state = {
        isFormValid: false,
        isFormSent: false
    }

    setIsFormValid(isFormValid: boolean) {
        this.setState({isFormValid});
    }

    clearFormSent() {
        this.setState({isFormSent: false});
    }

    disableButton() {
		this.setIsFormValid(false);
	}

	enableButton() {
		this.setIsFormValid(true);
	}

	handleSubmit = (event: any) => {

        var currentId = window.location.href.split("/").pop();
        currentId = currentId?.substring(1).replace('#', '');
        const buildingFound = BuildingsList.find(element => element.id === currentId);
        
        const { t } = this.props;

        if(buildingFound !== undefined){
            const mailInfo: MailData = ({
                id: buildingFound.id,
                buildingId: buildingFound.buildingId,
                buildingName: buildingFound.buidingName,
                firstName: event.firstname,
                lastName: event.lastname,
                email: event.email,
                description: event.description,
                language: t('common.lang')
            });
            // console.log(mailInfo);

            this.sendUserEmail(mailInfo);
            this.sendServiceDeskEmail(mailInfo);
        }

        this.setState({isFormSent: true});
	}

    sendUserEmail(mailInfo: MailData) {

        // console.log('sendUserEmail');
        // console.log(mailInfo);

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // 'Access-Control-Allow-Origin': 'https://servicedesk.cofinimmooffices.be/',
                // 'Access-Control-Allow-Methods': 'POST, PUT, GET, OPTIONS',
                // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization'
            },
            body: JSON.stringify(mailInfo)
        };
        fetch('https://servicedesk.cofinimmooffices.be:2999/sendMailToUser', requestOptions)
            .then(response => response.json())
            // .then(data => this.setState({ postId: data.id }));
    }

    sendServiceDeskEmail(mailInfo: MailData) {

        // console.log('sendServiceDeskEmail');
        // console.log(mailInfo);

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // 'Access-Control-Allow-Origin': 'https://servicedesk.cofinimmooffices.be/',
                // 'Access-Control-Allow-Methods': 'POST, PUT, GET, OPTIONS',
                // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization'
            },
            body: JSON.stringify(mailInfo)
        };
        fetch('https://servicedesk.cofinimmooffices.be:2999/sendMailToServiceDesk', requestOptions)
            .then(response => response.json())
            // .then(data => this.setState({ postId: data.id }));
    }

    render()
    {
        const { t } = this.props;

        if(this.state.isFormSent){
            return <div className="formDescription">
                {t('common.email-sent')}<br/><br/>
                <Button variant="contained" color="primary" onClick={() => this.clearFormSent()} >{t('formSent.new-issue')}</Button>    
            </div>
        }
        else {
            return(
                <><div className="formDescription">
                    {t('common.description')}
                </div>
                <div className="serviceDeskFormPanel">
                <Formsy
                    onValidSubmit={this.handleSubmit}
                    onValid={this.enableButton}
                    onInvalid={this.disableButton}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h3">{t('form.contact-information-title')}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextFieldFormsy
                                id="firstname"
                                name="firstname"
                                label={t('form.firstname')}
                                variant="outlined"
                                validations="isAlphanumeric"
                                validationError={t('formValidationErrors.firstname')}
                                required
                                fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextFieldFormsy
                                id="lastname"
                                name="lastname"
                                label={t('form.lastname')}
                                variant="outlined"
                                validations="isAlphanumeric"
                                validationError={t('formValidationErrors.lastname')}
                                required
                                fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextFieldFormsy
                                id="email"
                                name="email"
                                label={t('form.email')}
                                variant="outlined"
                                validations="isEmail"
                                validationError={t('formValidationErrors.email')}
                                required
                                fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="h3">{t('form.issue-description')}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextFieldFormsy
                                id="description"
                                name="description"
                                multiline
                                rows={10}
                                variant="outlined"
                                validations="isExisty"
                                validationError={t('formValidationErrors.description')}
                                fullWidth
                                required />
                        </Grid>
                        <Grid container alignItems="center" justifyContent="center">
                            <Button type="submit" variant="contained" color="primary" disabled={!this.state.isFormValid}>{t('form.submit-button')}</Button>
                        </Grid>
                    </Grid>
                </Formsy>
            </div></>
            )    
        }
    }
}

export default ServiceDeskForm;