import React, { useState } from "react"
import { Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Languages } from '../Enums/Languages';
 
function LanguageSwitch() {
    
    const { i18n } = useTranslation();
    const [lang, setLang] = useState<Languages>(i18n.language as Languages);

    let getActiveLanguage = (language: string) => { 
        if(lang !== language) return '#8b8681';
    }

    let changeLanguage = (language: string) => {

        switch (language) {
            case Languages.FR:
                setLang(Languages.FR);
                i18n.changeLanguage(Languages.FR);
                break;
            case Languages.NL:
                setLang(Languages.NL);
                i18n.changeLanguage(Languages.NL);
                break;
            case Languages.EN:
            default:
                setLang(Languages.EN);
                i18n.changeLanguage(Languages.EN);
                break;
        }
    }
 
    return (
        <div className="languageSelectorBar">
            <Link href="#" onClick={() => changeLanguage('fr')} style={{ color: getActiveLanguage('fr') }}>FR</Link>-<Link href="#" onClick={() => changeLanguage('en')} style={{ color: getActiveLanguage('en') }}>EN</Link>-<Link href="#" onClick={() => changeLanguage('nl')} style={{ color: getActiveLanguage('nl') }}>NL</Link>
        </div>
    )

}
 
export default LanguageSwitch;