import React, { PureComponent } from 'react';
import { CircularProgress } from '@material-ui/core';

class Loader extends PureComponent {

    componentWillMount () {
        document.body.style.backgroundColor = "#fff"
      }

    render() {
        return <div className="loaderStyle">
        <img
            src="../resources/images/cofinimmo-services-logo.gif"
            alt="Cofinimmo Services" />
        <CircularProgress /></div>
    }

}

export default Loader;