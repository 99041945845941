import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';

// Manage theme color
const Theme = createTheme({
    palette: {
      type: 'light',
      primary: {
        main: '#E10E49',
      },
      secondary: {
        main: '#E10E49',
      },
      text: {
        primary: "#E10E49",
      }
    },
    typography: {
      fontFamily: [
        'lato'
      ].join(','),
      h1: {
        fontSize: 50,
        // fontWeight: 'bold',
        fontFamily: [
          'aleo'
        ].join(','),
        color: "#E10E49",
      },
      h2: {
        fontSize: 24,
        fontWeight: 'bold',
        fontFamily: [
          'lato'
        ].join(','),
        color: "#8b8681",
      },
      h3: {
        fontSize: 20,
        fontFamily: [
          'aleo'
        ].join(','),
        color: "#E10E49",
      }
    },
    overrides: {
      MuiOutlinedInput: {
        root: {
           position: 'relative',
           '& $notchedOutline': {
              color: '#8b8681'
            //  borderColor: '#2EFF22',
           },
          //  '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          //    borderColor: '#2EFF22',
          //    // Reset on touch devices, it doesn't add specificity
          //    '@media (hover: none)': {
          //      borderColor: '#2EFF22',
          //     },
          //   },
           '&$focused $notchedOutline': {
             borderColor: '#8b8681',
             borderWidth: 2,
           },
        },
      },
      MuiInputBase: {
          root: {
            color:'#8b8681',
          }
      },
      // MuiFormLabel: {
        // root: {
          // '&$focused': {
            // color: '#2EFF22'
          // }
        // }
      // },
      MuiFormHelperText: {
        root: {
          '&$focused': {
            // color: '#2EFF22',
            fontWeight: 'bold'
          }
        }
      }
    }
  });
  
  export default responsiveFontSizes(Theme);