import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@material-ui/core/styles';
import Loader from './Components/Loader'
import { withTranslation } from 'react-i18next';
import theme from './Theme'

// import i18n (needs to be bundled ;)) 
import './i18n';

const MyApp = withTranslation()(App);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Suspense fallback={<Loader/>}>
          <MyApp />
      </Suspense>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('qrCodeRoot')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();